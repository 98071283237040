
import { ref, getCurrentInstance, computed, Ref } from 'vue';
import { getUrlKwaiId } from '@/common/utils'
import { UserApi } from '@/api/user';
import { PWAFromPage } from '~/api/base';

export const useList = (
  {
    feeds,
    ownerCount,
    isBlockedByOwner,
    isBlocked,
  }:
    {
      feeds: Ref<Array<Record<string, any>>>,
      ownerCount: Ref<Record<string, any>>,
      isBlockedByOwner: Ref<boolean>,
      isBlocked: Ref<boolean>,
    }
) => {
  const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }
  const loading = ref(false)
  const finished = computed(() => cursor.value === 'no_more')
  const cursor = ref('')
  const onLoad = async () => {
    const kwaiId = getUrlKwaiId()
    const feedRes = await UserApi.getPWAProfileFeed({
      userId: (kwaiId || '').replace(/^@/, ''),
      fromPage: PWAFromPage.PWA_PROFILE,
      beforePage: '',
      pcursor: cursor.value,
      count: 20 // 单次请求的post数量
    })
    if (feedRes?.result === 1) {
      cursor.value = feedRes.cursor
      feeds.value = [...feeds.value, ...feedRes.feeds]
    } else {
      cursor.value = 'no_more'
    }
    loading.value = false
  }

  const loadMorePost = () => {
    loading.value = true;
    onLoad();
  }



  const getUserFeed = async () => {
    const kwaiId = getUrlKwaiId()
    return await UserApi.getPWAProfileFeed({
      userId: (kwaiId || '').replace(/^@/, ''),
      fromPage: PWAFromPage.PWA_PROFILE,
      beforePage: '',
      count: 20 // 单次请求的post数量
    })
  }

  const postNum = computed(() => {
    if (isBlockedByOwner.value || isBlocked.value) {
      return 'X'
    }
    return ownerCount.value.photo_public || 0
  })

  const postText = computed(() => {
    if (postNum.value < 2) {
      return proxy.$root.$t('k_344305')
    }
    return proxy.$root.$t('k_335991')
  })

  return {
    cursor,
    finished,
    loading,
    postNum,
    loadMorePost,
    onLoad,
    getUserFeed,
    postText,
  };
};

