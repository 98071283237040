
import { defineComponent, getCurrentInstance, PropType, computed } from 'vue';
import { roundingNum } from '@/common/utils';

type prefile = {
    [key: string]: any;
};

interface OwnerCount {
    fan: number;
    follow: number;
    liked: number;
    like: number;
    photo: number;
    photo_private: number;
    photo_public: number;
}

export default defineComponent({
    components: {},
    props: {
        profile: {
            type: Object as PropType<prefile>,
            required: true,
        },
        ownerCount: {
            type: Object as PropType<OwnerCount>,
            required: true,
        },
        isFollowing: {
            type: Boolean,
            default: false,
            required: true,
        },
        isFollowed: {
            type: Boolean,
            default: false,
            required: true,
        },
        isBlockedByOwner: {
            type: Boolean,
            default: false,
            required: true,
        },
        followRequesting: {
            type: Boolean,
            default: false,
            required: true,
        },
        isPrivacyUser: {
            type: Boolean,
            default: false,
            required: true,
        },
        isBlocked: {
            type: Boolean,
            default: false,
            required: true,
        },
        isPoi: {
            type: Boolean,
            default: false,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },
    emits: ['clickInfo', 'clickFollow'],
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };

        const getGenderString = {
            M: proxy.$root.$t('gender_male'),
            F: proxy.$root.$t('gender_female'),
            N: proxy.$root.$t('gender_neuter'),
            S: proxy.$root.$t('gender_secret'),
        };

        // 吐了
        const followBtnText = computed(() => {
            // 隐私
            if (props.isPrivacyUser) {
                return proxy.$root.$t('k_335868');
            }
            // 互关
            if (props.isFollowing && props.isFollowed) {
                return proxy.$root.$t('k_336138');
            }
            // 被关注
            if (props.isFollowed) {
                return proxy.$root.$t('k_344308');
            }
            // 被拉黑了
            if (props.isBlocked) {
                return proxy.$root.$t('k_335868');
            }
            return proxy.$root.$t('k_335869');
        });
        const headerUrl = computed(() => {
            return props.profile?.headurl || 'https://cdn-static.kwai.net/kos/s101/nlav11312/pwa-images/default-head.png';
        });

        const sex = computed(() => {
            return getGenderString[props.profile.user_sex as keyof typeof getGenderString] || '';
        });

        const handleInfoClick = (type: string) => {
            ctx.emit('clickInfo', { type });
        };

        const clickFollow = () => {
            ctx.emit('clickFollow');
        };

        const handleClickLocal = () => {
            proxy.$bus.emit('launchApp', { callAppPath: `profile/${props.userId}` });
        };

        return {
            proxy,
            headerUrl,
            sex,
            getGenderString,
            followBtnText,
            handleClickLocal,
            clickFollow,
            roundingNum,
            handleInfoClick,
        };
    },
});
