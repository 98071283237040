// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/back-black.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/share.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav[data-v-e2264daa] {\n  background: #fff;\n  height: 15.4589vw;\n  width: 100%;\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 4.5894vw;\n}\n.nav .back-img[data-v-e2264daa] {\n  width: 7.7295vw;\n  height: 7.7295vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;\n  flex-shrink: 0;\n}\n.nav .user-name[data-v-e2264daa] {\n  padding-left: 2.8986vw;\n  height: 7.7295vw;\n  line-height: 7.7295vw;\n  flex: 1;\n  font-weight: 700;\n  font-size: 4.8309vw;\n  padding-right: 1.9324vw;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.nav .share-btn[data-v-e2264daa] {\n  width: 7.7295vw;\n  height: 7.7295vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center/100% no-repeat;\n  flex-shrink: 0;\n}\n@media screen and (min-width: 750px) {\n.nav[data-v-e2264daa] {\n    width: 750px;\n    height: 64px;\n    padding: 0 19px;\n}\n.nav .back-img[data-v-e2264daa] {\n    width: 32px;\n    height: 32px;\n}\n.nav .user-name[data-v-e2264daa] {\n    padding-left: 12px;\n    height: 32px;\n    line-height: 32px;\n    flex: 1;\n    font-size: 20px;\n    padding-right: 8px;\n}\n.nav .share-btn[data-v-e2264daa] {\n    width: 32px;\n    height: 32px;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
