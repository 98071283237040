
import { Ref, getCurrentInstance } from 'vue';
import Toast from 'vant/lib/toast';
import { sendLog } from '@/common/radar';
import { getUrlKwaiId } from '@/common/utils'
import { PWAFromPage } from '@/api/base';

export const usePrefileInfo = (
  {
    showLoginPopup,
    needCheckLogin,
    showNewOpenKwaiNotice,
    followCb,
    userId,
  }:
    {
      showLoginPopup: Ref<boolean>,
      showNewOpenKwaiNotice: Ref<boolean>,
      needCheckLogin: Function
      followCb: Function
      userId: Ref<string>
    }
) => {
  const { proxy } = getCurrentInstance() as { proxy: any }

  const clickFollow = ({ isFollowing }: { isFollowing: boolean }) => {
    sendLog({
      type: 'click',
      name: 'FOLLOW_BUTTON',
      value: {
        author_id: userId.value
      }
    })
    let kwaiId = getUrlKwaiId() as string
    needCheckLogin(() => follow({ kwaiId, fType: 1 }))
  }

  const follow = async ({ kwaiId, fType }: { kwaiId: string, fType: 0 | 1 | 2 }) => {
    const { result, errorMsg, data } = await proxy.$axios.$$post('overseaAPI::/rest/o/w/pwa/followAction', {
      toUid: kwaiId,
      fType, //
      noticeId: '',

      sourcePageTag: window.location.href.replace(/\?.*/, ''),
      fromPage: PWAFromPage.PWA_PROFILE,
      contentId: kwaiId,
      contentType: 'profile',
    })

    if (result === 1) {
      // 关注已发送，对方设置了隐私保护
      if (data?.followStatus === 2) {
        Toast({
          message: proxy.$root.$t('k_327834'),
        })
      }
      followCb()
    } else if (result === 109 || result === 1047232003) {
      showLoginPopup.value = true;
    } else {
      errorMsg && Toast({
        message: errorMsg,
      })
    }
  }

  const clickMessage = () => {
    showNewOpenKwaiNotice.value = true
  }

  return {
    clickFollow,
    clickMessage
  };
};

