
import { ref, getCurrentInstance, computed, Ref } from 'vue';
import { getUrlKwaiId } from '@/common/utils'
import { UserApi } from '~/api/user';

interface OwnerCount {
  fan: number
  follow: number
  like: number
  liked: number
  photo: number
  photo_private: number
  photo_public: number
}

export const useInfo = () => {
  const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }

  let profile: Ref<{
    user_name: string
    headurl?: string
    user_id?: string
    poiCategory?: string
    poi?: boolean
  }> = ref({ user_name: '' })
  let isFollowing = ref(false) // 我关注对方
  let isFollowed = ref(false) // 对方关注我
  let followRequesting = ref(false) // 对方开了隐私 并且发送了请求
  let isBlocked = ref(false) // 被ban了
  let isBlockedByOwner = ref(false) // 把对面ban了
  let isPrivacyUser = ref(false) // 隐私用户
  let feeds = ref([] as any)
  let ownerCount: Ref<OwnerCount> = ref({
    fan: 0,
    follow: 0,
    like: 0,
    liked: 0,
    photo: 0,
    photo_private: 0,
    photo_public: 0,
  })

  const userName = computed(() => {
    return profile.value?.user_name || ''
  })

  const userId = computed(() => {
    return profile?.value?.user_id && `${profile?.value?.user_id}` || ''
  })

  const isPoi = computed(() => {
    return profile.value?.poi || false
  })

  // 刷新info信息
  const refreshInfo = async () => {
    const profileRes = await getUserInfo()
    if (profileRes?.result === 1) {
      const { userProfile } = profileRes
      profile.value = {
        ...userProfile?.profile
      };
      ownerCount.value = {
        ...userProfile?.ownerCount
      }
      setUserProfile(userProfile)
    }
  }

  const setUserProfile = (userProfile: Record<string, boolean>) => {
    isFollowing.value = userProfile?.isFollowing
    isBlocked.value = userProfile?.isBlocked
    followRequesting.value = userProfile?.followRequesting
    isFollowed.value = userProfile?.isFollowed
    isBlockedByOwner.value = userProfile?.isBlockedByOwner
    isPrivacyUser.value = userProfile?.isPrivacyUser
  }

  // 请求数据相关
  const getUserInfo = async () => {
    const kwaiId = getUrlKwaiId()
    return await UserApi.getPWAProfileInfo({
      userId: (kwaiId || '').replace(/^@/, ''),
      mcc: '',
      deviceId: '',
      fromPhoto: false
    })
  }

  return {
    profile,
    isFollowing,
    isFollowed,
    followRequesting,
    isBlocked,
    userName,
    feeds,
    ownerCount,
    isBlockedByOwner,
    isPrivacyUser,
    userId,
    isPoi,
    refreshInfo,
    getUserInfo,
    setUserProfile,
  };
};

