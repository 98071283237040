
import { ref, getCurrentInstance, onMounted, watch } from 'vue';
import { isLogin, tinyLogin } from '@/common/login';
import { isTiny } from '~/common/utils';
import { SafetyLocalStorage } from '@/common/storage';

export const useLogin = () => {
  const { proxy } = getCurrentInstance() as { proxy: any }

  const DefaultUserHeadUrl = 'https://cdn-static.kwai.net/kos/s101/nlav11312/pwa-images/default-head.png'

  const showLoginPopup = ref(false) // 是否展示登陆
  const isLoginStatus = ref(false) // 当前是否为登录
  const showLogoutPopup = ref(false) // 是否展示登出弹窗
  const currentUserHeadUrl = ref(DefaultUserHeadUrl)

  const changeLoginStatus = (val: boolean, cb?: Function) => {
    isLoginStatus.value = val
    cb && cb()
  }

  const handleLoginPopupClose = () => {
    showLoginPopup.value = false
  }

  const changeLoginPopupShow = (val: boolean) => {
    showLoginPopup.value = val
  }

  // 拉kwai
  const handleLoginPopupOpenkwai = (cb?: Function) => {
    showLoginPopup.value = false
    proxy.$bus.emit('launchApp', { callAppPath: 'home' })
  }

  const needCheckLogin = async(cb: Function) => {
    if (!isLoginStatus.value && !proxy.$store.state.isLogin) {
      if (isTiny() && !proxy.$store.state.isLogin) {
        await tinyLogin(proxy)
        typeof cb === 'function' && cb()
      } else if(!isLoginStatus.value) {
        showLoginPopup.value = true
      }
    } else {
      typeof cb === 'function' && cb()
    }
  }

  const handleLogoutPopupShow = (val: boolean) => {
    showLogoutPopup.value = val
  }

  const handleLogoutCancel = () => {
    showLogoutPopup.value = false
  }

  const handleLogout = () => {
    isLoginStatus.value = false
    showLogoutPopup.value = false
  }

  onMounted(() => {
    // 进入页面渲染时 校验当前登录态
    const { proxy } = getCurrentInstance() as { proxy: any }
    isLogin().then((res) => {
      isLoginStatus.value = res
    })
  })

  watch(() => isLoginStatus.value, async (newValue) => {
    if (newValue) {
      try {
        const storedUrl = SafetyLocalStorage?.getItem('CURRENT_USER_HEADURL');
        currentUserHeadUrl.value = storedUrl || DefaultUserHeadUrl;
      } catch (error) {
        console.error('Error accessing SafetyLocalStorage:', error);
        currentUserHeadUrl.value = DefaultUserHeadUrl;
      }
    } else {
      currentUserHeadUrl.value = DefaultUserHeadUrl;
    }
  })

  return {
    showLoginPopup,
    showLogoutPopup,
    isLoginStatus,
    currentUserHeadUrl,

    handleLoginPopupClose,
    changeLoginStatus,
    handleLoginPopupOpenkwai,
    needCheckLogin,
    changeLoginPopupShow,
    handleLogoutCancel,
    handleLogout,
    handleLogoutPopupShow,
  };
};

