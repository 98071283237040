
import Vue, { defineComponent, getCurrentInstance } from 'vue';

export default defineComponent({
    components: {},
    props: {
        userName: {
            type: String,
            required: true,
            default: '',
        },
    },
    setup(_: unknown, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: Vue };

        const handleShareClick = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('share', { type: 'share' });
        };

        return {
            proxy,
            handleShareClick,
        };
    },
});
