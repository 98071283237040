
import cookies from 'js-cookie';
import { defineComponent, watch, getCurrentInstance, onMounted, ref, onUnmounted, defineAsyncComponent } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import vanTabs from 'vant/lib/tabs';
import vanTab from 'vant/lib/tab';
import { usePrefileInfo } from './components/user-profile-base-info/useProfileInfo';
import { useList } from './hooks/useList';
import { useInfo } from './hooks/useInfo';
import { useNoticePopup } from './hooks/useNoticePopup';
import { roundingNum, getUrlKwaiId, commonPvParams } from '@/common/utils';
import { useVisiableTime } from '@/hooks/useVisiableTime';
import { useLogin } from '@/hooks/useLogin';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { immediateSendLog, getKwaiLog, sendLog } from '@/common/radar';
import { header, extractTitleFromMetaInfo, getDefaultTitle } from '@/common/seo';
import { SEOApi } from '@/api/seo';
import { UserApi } from '@/api/user';
import { PWAFromPage } from '@/api/base';
import List from './components/list/index.vue';
import NavBar from './components/nav/index.vue';
import UserBaseInfo from './components/user-profile-base-info/index.vue';

export default defineComponent({
    components: {
        UserBaseInfo,
        vanTab,
        vanTabs,
        List,
        NavBar,
        LoginPopup: defineAsyncComponent(() => import(/* webpackChunkName: "components/login-popup" */ '@/components/login/index.vue')),
        NewOpenKwaiNotice: defineAsyncComponent({
            loader: () => import(/* webpackChunkName: "components/new-open-kwai-notice" */ '@/components/new-open-kwai-notice/index.vue'),
        }),
        newEmpty: defineAsyncComponent({
            loader: () => import(/* webpackChunkName: "components/new-empty" */ '@/components/new-empty/index.vue'),
        }),
    },
    layout(ctx) {
        return ctx.$pwaLayout || 'default';
    },
    // layout: 'noFooterLayout',
    setup() {
        const fmp_time = Date.now();
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };
        const albums = ref([]);
        const albumcount = ref(0);

        // 登录 hooks
        const { showLoginPopup, isLoginStatus, handleLoginPopupClose, changeLoginStatus, handleLoginPopupOpenkwai, needCheckLogin } = useLogin();

        // info
        const {
            profile,
            isFollowing,
            isFollowed,
            followRequesting,
            isBlockedByOwner,
            isBlocked,
            isPrivacyUser,
            userName,
            feeds,
            ownerCount,
            userId,
            isPoi,
            refreshInfo,
            setUserProfile,
            getUserInfo,
        } = useInfo();

        // // notice popup hooks
        const { showNewOpenKwaiNotice, handleNewOpenKwaiNoticeClose, handleNewOpenKwaiNotice } = useNoticePopup({ userId });

        useVisiableTime({ userId });

        // 用户详情展示 hooks
        const { clickFollow, clickMessage } = usePrefileInfo({
            showLoginPopup,
            needCheckLogin,
            followCb: refreshInfo,
            showNewOpenKwaiNotice,
            userId,
        });

        // list hooks
        const { cursor, finished, loading, postNum, postText, loadMorePost, getUserFeed } = useList({
            feeds,
            ownerCount,
            isBlockedByOwner,
            isBlocked,
        });

        // tab hooks 目前没逻辑
        const active = ref(0);

        // 公共拉起快
        const openKwaiPopup = (type: string) => {
            sendLog({
                type: 'show',
                name: 'PWA_PROFILE_POP',
                value: {
                    pop_type: 'download_pop',
                    author_id: userId.value,
                },
            });
            showNewOpenKwaiNotice.value = true;
        };

        watch(
            () => showLoginPopup.value,
            newVal => {
                if (newVal) {
                    sendLog({
                        type: 'show',
                        name: 'PWA_PROFILE_POP',
                        value: {
                            button_type: 'login_pop',
                            author_id: userId.value,
                        },
                    });
                }
            }
        );

        // watch(() => isPrivacyUser.value, (newValue) => {
        //   if (newValue) {
        //     Toast({
        //       message: proxy.$root.$t('k_327320'),
        //     })
        //   }
        // })

        const initData = async () => {
            loading.value = true;

            const [profileRes, feedRes] = await Promise.all([getUserInfo(), getUserFeed()]);
            loading.value = false;

            // 隐私用户
            if (profileRes?.result === 1047232028 || feedRes?.result === 1047232028) {
                isPrivacyUser.value = true;
            }

            if (profileRes?.result === 1) {
                const { userProfile } = profileRes;
                profile.value = {
                    ...userProfile?.profile,
                };
                ownerCount.value = {
                    ...userProfile?.ownerCount,
                };
                setUserProfile(userProfile);
            }
            if (feedRes?.result === 1) {
                feeds.value = feedRes?.feeds;
                cursor.value = feedRes?.cursor;
                albums.value = feedRes?.albums;
                albumcount.value = feedRes?.albumCount;
            }
        };

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'guest_profile' });
            // 获取启动config
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');
            const referrer = document?.referrer;
            immediateSendLog({
                type: 'pv',
                name: 'PWA_GUEST_PROFILE',
                value: {
                    country,
                    user_id,
                    ...params,
                },
            });
        };

        onMounted(() => {
            const webLogger = getKwaiLog();
            proxy.$bus.emit('isLoad', false);
            try {
                webLogger?.plugins?.radar?.fmp(fmp_time);
            } catch (error) {
                console.error(error);
            }
            proxy.$bus.on('sendPv', sendPv);
            sendPv();

            const profileServerData = proxy?.userProfile?.profile;
            const ownerCountServerData = proxy?.userProfile?.ownerCount;
            const feedsServerData = proxy?.feedsData?.feeds;
            const pcursorServerData = proxy?.feedsData?.cursor;
            const albumsServerData = proxy?.feedsData?.albums;
            const albumCountServerData = proxy?.feedsData?.albumCount;

            if (profileServerData) {
                profile.value = profileServerData;
            }

            setUserProfile(proxy?.followType);

            if (ownerCountServerData) {
                ownerCount.value = ownerCountServerData;
            }
            if (feedsServerData) {
                feeds.value = feedsServerData;
                cursor.value = pcursorServerData;
                albums.value = albumsServerData;
                albumcount.value = albumCountServerData;
                // albums: feedRes?.albums,
                // albumcount: feedRes?.Albumcount
            }
            // 从别的界面跳转过来 前端调用请求
            if (!proxy.isServerFetched) {
                initData();
            }
        });

        onUnmounted(() => {
            proxy.$bus.off('sendPv', sendPv);
        });

        // onActivated(() => {
        //   proxy.$bus.emit('isLoad', false)
        // })

        return {
            active,
            postNum,
            showLoginPopup,
            isLoginStatus,
            profile,
            ownerCount,
            proxy,
            userName,
            feeds,
            showNewOpenKwaiNotice,
            isFollowing,
            isBlocked,
            loading,
            finished,
            isFollowed,
            followRequesting,
            isBlockedByOwner,
            isPrivacyUser,
            postText,
            albums,
            albumcount,
            isPoi,
            userId,
            clickFollow,
            clickMessage,
            handleLoginPopupClose,
            changeLoginStatus,
            handleLoginPopupOpenkwai,
            openKwaiPopup,
            handleNewOpenKwaiNoticeClose,
            handleNewOpenKwaiNotice,
            loadMorePost,
            refreshInfo,
            roundingNum,
        };
    },
    async asyncData(ctx: Context) {
        let userProfile = {};
        let feedsData = {};
        let seoData = [];
        let metaInfoData = [];
        let title = getDefaultTitle(ctx);
        const followType = {
            isFollowing: false,
            isBlocked: false,
            isOwner: false,
            followRequesting: false,
            isFollowed: false,
            isBlockedByOwner: false,
            isPrivacyUser: false,
        };
        const kwaiId = getUrlKwaiId(ctx);

        let isServerFetched = false; // 前端切换路由时 asyncData也会调用 判断是否在服务端获取过数据
        if (process.server) {
            const { mcc } = ctx.route.query;
            isServerFetched = true;
            // 预请求接口
            try {
                const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || '';
                let pidStrList = [];

                // 获取meta info
                const metaInfoPromise = SEOApi.getMetaInfo({ url, locale: ctx.req.headers['accept-language'] });

                setCommonParamsCookiesInServer({
                    bucket: ctx.store.state.startup.bucket,
                    host: ctx.req.headers.host,
                    ctx,
                    countryInfo: ctx.store.state.startup.countryInfo,
                });

                const profilePromise = UserApi.getPWAProfileInfo({
                    // userId: ctx.route.params.userId,
                    userId: (kwaiId || '').replace(/^@/, ''),
                    mcc,
                    deviceId: '',
                    fromPhoto: false,
                });

                const feedPromise = UserApi.getPWAProfileFeed({
                    // userId: ctx.route.params.userId,
                    userId: (kwaiId || '').replace(/^@/, ''),
                    beforePage: '',
                    fromPage: PWAFromPage.PWA_PROFILE,
                    count: 20, // 单次请求的post数量
                });

                const [profileRes, feedRes, metaInfoRes] = await Promise.all([profilePromise, feedPromise, metaInfoPromise]);

                // 隐私用户
                if (profileRes?.result === 1047232028 || feedRes?.result === 1047232028) {
                    followType.isPrivacyUser = true;
                }
                if (profileRes?.result === 1) {
                    userProfile = {
                        ...profileRes.userProfile,
                    };

                    followType.isFollowing = profileRes?.userProfile?.isFollowing;
                    followType.isBlocked = profileRes?.userProfile?.isBlocked;
                    followType.isOwner = profileRes?.userProfile?.isOwner;
                    followType.followRequesting = profileRes?.userProfile?.followRequesting;
                    followType.isFollowed = profileRes?.userProfile?.isFollowed;
                    followType.isBlockedByOwner = profileRes?.userProfile?.isBlockedByOwner;
                }
                // console.log('feedRes', feedRes)
                if (feedRes?.result === 1) {
                    feedsData = {
                        feeds: feedRes?.feeds,
                        cursor: feedRes?.cursor,
                        albums: feedRes?.albums,
                        albumCount: feedRes?.albumCount,
                    };
                    pidStrList = feedRes?.feeds?.map((item: { photo_id_str?: any }) => item?.photo_id_str)?.filter((pid: any) => pid !== undefined);
                }

                if (metaInfoRes.status === 200) {
                    metaInfoData = metaInfoRes.data;
                    title = extractTitleFromMetaInfo({ ctx, metaInfoData });
                }

                // 获取google ldjson 标签
                const seoPromise = SEOApi.getLdJson({ url, pidStrList: pidStrList || [] });

                const seoRes = await seoPromise;
                if (seoRes.status === 200) {
                    seoData = seoRes.data;
                }
            } catch (error) {
                console.log(error);
            }
        }
        return {
            userProfile,
            feedsData,
            followType,
            isServerFetched,
            seoData,
            metaInfoData,
            title,
        };
    },
    head() {
        const head = header(this);
        return {
            ...head,
        };
    },
});
