
import { ref, getCurrentInstance, computed, Ref } from 'vue';
import { sendLog, LogType } from '@/common/radar';

export const useNoticePopup = ({ userId }: { userId: Ref<string> }) => {
  const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }

  const showNewOpenKwaiNotice = ref(false)
  const handleNewOpenKwaiNoticeClose = () => {
    showNewOpenKwaiNotice.value = false
    sendPopShowLog({
      logType: 'click',
      params: {
        button_type: 'wait'
      }
    })
  }

  const handleNewOpenKwaiNotice = (params: Record<string, string>) => {
    proxy.$bus.emit('launchApp', { callAppPath: `profile/${userId.value}` })
    sendPopShowLog({
      logType: 'click',
      params: {
        button_type: 'download'
      }
    })
    setTimeout(() => {
      showNewOpenKwaiNotice.value = false
    }, 0)
  }

  const sendPopShowLog = (logParams: { logType: keyof typeof LogType, params?: Record<string, string> }) => {
    const { logType, params } = logParams
    const valueParams: any = {
      author_id: proxy.$route.params.userId,
      ...params
    }

    sendLog({
      type: logType,
      name: 'PWA_PROFILE_POP',
      value: {
        pop_type: 'download_pop',
        ...valueParams
      }
    })
  }

  return {
    showNewOpenKwaiNotice,
    handleNewOpenKwaiNoticeClose,
    handleNewOpenKwaiNotice,
    sendPopShowLog
  };
};

