
import { onMounted, ref, onUnmounted, Ref } from 'vue';
import { sendLog, immediateSendLog, getKwaiLog } from '@/common/radar';

export const useVisiableTime = ({ userId, logParams }: { userId: Ref<string>, logParams?: any }) => {
  let startTime = ref(new Date().getTime())

  const consume_duration = () => {
    const consume_duration = new Date().getTime() - startTime.value
    const webLogger = getKwaiLog();
    // 停留时长
    webLogger.sendImmediately('PV', {
      type: 'leave',
      beginTime: startTime.value,
    });

    immediateSendLog({
      type: 'click',
      name: 'CONSUME_DURATION',
      value: {
        type: 'STAY_LENGTH_STAT_EVENT',
        author_id: userId.value,
        duration_time: consume_duration,
        ...logParams
      }
    })

    // 每次触发离开事件 都重新计时
    startTime.value = new Date().getTime()
  }
  // 测试停留时长hooks
  onMounted(() => {
    window.addEventListener('beforeunload', consume_duration);
  })

  onUnmounted(() => {
    window.removeEventListener('beforeunload', consume_duration);
  })

  return {

  };
};

